import React from 'react';
import '../App.css'; // Ensure this import is correct
import D3BarChart from './D3BarChart';
import D3ScatterPlot from './D3ScatterPlot'; // Import the new scatter plot component
import ObservableMap from './ObservableMap'; // Import the Observable map component
import ObservableRadial from './ObservableRadial'; 

const ExecDashboard = () => {
  return (
    <section id="exec-dashboard" className="py-20 bg-white">
      <div className="container mx-auto px-4 md:px-0">
        <h2 className="text-4xl font-bold mb-8 text-center">Dashboard Proposal</h2>
        <div className="mb-8">
          <img
            src="/images/ExecDashboard.png"
            alt="Exec Dashboard"
            className="banner-image w-full h-auto mx-auto"
          />
        </div>
        <div className="text-center">
          <h3 className="text-3xl font-bold mb-4">Exec Dashboard</h3>
          <p className="text-xl text-gray-700 mb-8">Exec level metrics at a glance</p>
        </div>
        <div className="text-left">
          <h4 className="text-2xl font-bold mb-4">The Opportunity</h4>
          <p className="text-lg text-gray-700 mb-8">
            My client had many scattered reports and dashboards providing different business metrics, which were difficult to consume. Those at a Director level, needed to see top level metrics without friction, so they could scan business performance at a glance and target poor performance. They also wanted an ability to dig further down on any metrics in question, to a lower level.
          </p>
          <h4 className="text-2xl font-bold mb-4">My First Few Days</h4>
          <p className="text-lg text-gray-700 mb-8">
            (Add additional content here)
          </p>
        </div>
        <D3BarChart />
        <D3ScatterPlot /> {/* Add the scatter plot component here */}
        <ObservableMap /> {/* Add the Observable map component here */}
        <ObservableRadial /> {/* Add the Observable map component here */}
      </div>
    </section>
  );
}

export default ExecDashboard;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing

const Header = () => {
  return (
    <header className="bg-yellow-50 py-20">
      <div className="container mx-auto flex flex-col items-center text-center px-4 md:px-0">
        <div className="text-5xl font-bold mb-4">We help build better analytics experiences</div>
        <div className="text-5xl font-bold text-[#4D46DE] mb-4">DataUX</div>
        <p className="text-xl text-gray-700 mb-8">
          Welcome to DataUX (dataux.co.uk) where we help build better Analytics Experiences using Human Centred Design and years of experience in the Analytics/BI field. 
          I'm a certified UX Designer who has designed and implemented many products and services, from initial Data Warehouse creation to Application development. 
          I’ve worked with many leading brands in a variety of roles and projects.
        </p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <button className="px-4 py-2 bg-[#4D46DE] text-white rounded">Say Hello</button>
          <Link to="/projects">
            <button className="px-4 py-2 border border-[#4D46DE] text-[#4D46DE] rounded">My Portfolio</button>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;

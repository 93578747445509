import React, { useEffect, useRef } from 'react';
import { Runtime, Inspector } from '@observablehq/runtime';
import define from 'https://api.observablehq.com/d/5b69429355b2dfe0.js?v=3'; // Use the URL of your forked notebook

const ObservableMap = () => {
  const mapRef = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(define, Inspector.into(mapRef.current));
    return () => runtime.dispose();
  }, []);

  return <div ref={mapRef}></div>;
};

export default ObservableMap;

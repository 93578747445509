import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white border-b">
      <div className="container mx-auto px-4 md:px-0">
        <div className="flex justify-between items-center py-4">
          <div className="text-2xl font-bold text-[#4D46DE]">DataUX</div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
              </svg>
            </button>
          </div>
          <div className={`flex-col md:flex-row md:flex ${isOpen ? 'flex' : 'hidden'} md:space-x-4 mt-4 md:mt-0`}>
            <Link to="/" className="text-gray-800 px-2 py-1 md:px-4">Home</Link>
            <Link to="/about" className="text-gray-800 px-2 py-1 md:px-4">About</Link>
            <Link to="/projects" className="text-gray-800 px-2 py-1 md:px-4">Projects</Link>
            <Link to="/blog" className="text-gray-800 px-2 py-1 md:px-4">Blog</Link>
            <a href="#services" className="text-gray-800 px-2 py-1 md:px-4">Services</a>
            <a href="#contact" className="text-gray-800 px-2 py-1 md:px-4">Contact</a>
            <button className="px-4 py-2 bg-[#4D46DE] text-white rounded mt-4 md:mt-0">Let's Talk</button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4 text-[#4D46DE]">About Me</h2>
        <p className="text-xl text-gray-700 mb-8">
          I'm John Ramsbottom, a certified UX Designer with a passion for building better Analytics Experiences through Human Centred Design. With years of experience in the Analytics/BI field, I've designed and implemented numerous products and services, from initial Data Warehouse creation to Application development. 
        </p>
        <h2 className="text-4xl font-bold mb-4 text-[#4D46DE]">About DataUX</h2>
        <p className="text-xl text-gray-700 mb-8">
          DataUX (dataux.co.uk) is dedicated to creating innovative and user-friendly analytics solutions. We specialise in bridging the gap between complex data and user experience, ensuring that analytics tools are not only powerful but  easy to use.
        </p>
      </div>
    </section>
  );
}

export default About;

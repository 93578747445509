import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Blog from './components/Blog';
import UsingVisualHierarchy from './components/UsingVisualHierarchy';
import ExecDashboard from './components/ExecDashboard'; // Add this import
import './App.css';

const App = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="viewport-width">
        Viewport width: {width}px
      </div>
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/using-visual-hierarchy" element={<UsingVisualHierarchy />} />
        <Route path="/exec-dashboard" element={<ExecDashboard />} /> {/* Add this route */}
      </Routes>
    </div>
  );
}

export default App;

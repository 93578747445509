import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const D3ScatterPlot = () => {
  const scatterRef = useRef();

  useEffect(() => {
    const data = Array.from({ length: 50 }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
    }));

    const svg = d3.select(scatterRef.current)
      .attr('width', 600)
      .attr('height', 400)
      .style('overflow', 'visible')
      .style('margin', '50px');

    const xScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, 600]);

    const yScale = d3.scaleLinear()
      .domain([0, 100])
      .range([400, 0]);

    svg.selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', 5)
      .attr('fill', 'green');

    svg.append('g')
      .attr('transform', 'translate(0, 400)')
      .call(d3.axisBottom(xScale));

    svg.append('g')
      .call(d3.axisLeft(yScale));

    return () => {
      svg.selectAll('.dot').remove();
      svg.selectAll('g').remove();
    };
  }, []);

  return <svg ref={scatterRef}></svg>;
}

export default D3ScatterPlot;

import React from 'react';

const UsingVisualHierarchy = () => {
  const projects = [
    {
      title: "test",
      category: "New",
      image: "/images/test.jpg", // Add image path if necessary
    },
  ];

  return (
    <section id="using-visual-hierarchy" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center px-4 md:px-0">
        <h2 className="text-4xl font-bold mb-8">Explore My Latest Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-700">{project.category}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default UsingVisualHierarchy;

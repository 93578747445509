import React from 'react';

const Blog = () => {
  const posts = [
    {
      title: "I've changed this. Understanding Human-Centered Design",
      date: "January 5, 2024",
      excerpt: "A deep dive into the principles of human-centered design and how it can improve your analytics experience.",
      image: "/images/human-centered-design.jpg", // Update with actual image paths
    },
    {
      title: "The Future of Analytics in 2024",
      date: "February 15, 2024",
      excerpt: "Exploring upcoming trends and innovations in the analytics and BI field.",
      image: "/images/future-of-analytics.jpg", // Update with actual image paths
    },
    {
      title: "Top UX Design Tips for Developers",
      date: "March 20, 2024",
      excerpt: "Practical UX design tips to enhance your application development process.",
      image: "/images/ux-design-tips.jpg", // Update with actual image paths
    },
  ];

  return (
    <section id="blog" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-2xl font-bold mb-2">{post.title}</h3>
                <p className="text-gray-600 text-sm mb-2">{post.date}</p>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
                <button className="px-4 py-2 bg-[#4D46DE] text-white rounded">Read More</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blog;

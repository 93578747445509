import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const D3BarChart = () => {
  const chartRef = useRef();
  const [data, setData] = useState([30, 80, 45, 60, 20, 90, 55]);
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    const svg = d3.select(chartRef.current)
      .attr('width', 600 * zoomLevel)
      .attr('height', 400 * zoomLevel)
      .style('overflow', 'visible')
      .style('margin', '50px');

    const xScale = d3.scaleBand()
      .domain(data.map((val, i) => i))
      .range([0, 600 * zoomLevel])
      .padding(0.5);

    const yScale = d3.scaleLinear()
      .domain([0, 100])
      .range([400 * zoomLevel, 0]);

    svg.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => xScale(i))
      .attr('y', yScale)
      .attr('width', xScale.bandwidth())
      .attr('height', val => 400 * zoomLevel - yScale(val))
      .attr('fill', 'blue')
      .on('click', function () {
        const isActive = d3.select(this).classed('active');
        d3.selectAll('.bar').classed('active', false).attr('fill', 'blue');
        if (!isActive) {
          d3.select(this).classed('active', true).attr('fill', 'orange');
        }
      });

    svg.append('g')
      .attr('transform', `translate(0, ${400 * zoomLevel})`)
      .call(d3.axisBottom(xScale));

    svg.append('g')
      .call(d3.axisLeft(yScale));

    return () => {
      svg.selectAll('.bar').remove();
      svg.selectAll('g').remove();
    };
  }, [data, zoomLevel]);

  const handleReset = () => {
    d3.selectAll('.bar').classed('active', false).attr('fill', 'blue');
  };

  const handleZoomIn = () => setZoomLevel(zoomLevel + 0.1);
  const handleZoomOut = () => setZoomLevel(zoomLevel - 0.1);

  return (
    <div>
      <svg ref={chartRef}></svg>
      <button onClick={handleReset} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
        Reset
      </button>
      <button onClick={handleZoomIn} className="mt-4 px-4 py-2 bg-green-500 text-white rounded ml-2">
        Zoom In
      </button>
      <button onClick={handleZoomOut} className="mt-4 px-4 py-2 bg-red-500 text-white rounded ml-2">
        Zoom Out
      </button>
      <div className="mt-4">
        <p className="text-lg"><span style={{ color: 'blue' }}>■</span> Bar Value</p>
        <p className="text-lg"><span style={{ color: 'orange' }}>■</span> Selected Bar</p>
      </div>
    </div>
  );
}

export default D3BarChart;

import React, { useEffect, useRef } from 'react';
import { Runtime, Inspector } from '@observablehq/runtime';
import define from 'https://api.observablehq.com/d/1b269451eb8a74cf.js?v=3'; // Use the URL of your new forked notebook

const ObservableRadial = () => {
  const visualRef = useRef();

  useEffect(() => {
    const runtime = new Runtime();
    runtime.module(define, Inspector.into(visualRef.current));
    return () => runtime.dispose();
  }, []);

  return <div ref={visualRef}></div>;
};

export default ObservableRadial;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import '../App.css'; // Ensure this import is correct

const Projects = () => {
  const projects = [
    {
      title: "Exec Dashboard",
      category: "Dashboard Design",
      image: "/images/ExecDashboardThumb.png",
      link: "/exec-dashboard", // Add link
    },
    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },
    {
      title: "Customer Data Warehouse",
      category: "Data Warehouse Build",
      image: "/images/CustomerDataWarehouseThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },    {
      title: "Covid Analysis",
      category: "Dashboard",
      image: "/images/CovidAnalysisThumb.png",
    },
  ];

  return (
    <section id="projects" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center px-4 md:px-0">
        <h2 className="text-4xl font-bold mb-8">Explore My Latest Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <Link to={project.link || '#'} key={index} className="bg-white rounded-lg shadow-lg project-card"> {/* Use Link */}
              <img 
                src={project.image} 
                alt={project.title} 
                className="project-thumbnail"
              />
              <div className="p-4">
                <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-700">{project.category}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
